import React from 'react';
import './index.css';

import useMargin from '../../utils/useMargin';

function RegexInput(props) {

    function validateRegex(value) {
        try {
            new RegExp(value);
        } catch (e) {
            return false;
        }
        return true;
    }

    function onChange(e) {
        props.setRegex(e.target.value);
        if (validateRegex(e.target.value)) {
            document.getElementById(props.configId + '-regex').classList.remove('invalid');
            props.setValid(true)
        } else {
            document.getElementById(props.configId + '-regex').classList.add('invalid');
            props.setValid(false)
        }
    }

    return (
        <input
            id={props.configId + '-regex'}
            type="text"
            className="regex-input"
            value={props.regex}
            onChange={onChange}
            style={{ marginRight: useMargin() + 'px' }}
            disabled={props.loading}
        />
    )
}

export default RegexInput;