import React from 'react';
import Spinner from '../Spinner'
import './index.css';


function ConfigFooter(props) {
    const { configId, valid, changed, handleSave, handleDelete, saveLoading, deleteLoading } = props;
    return (
        <div id={"config-footer" + configId} className="config-footer">
            <button className='button save' onClick={handleSave} disabled={!valid || !changed}>
                {saveLoading ? <Spinner /> : 'Save'}
            </button>
            <button className="button delete" onClick={handleDelete}>
                {deleteLoading ? <Spinner /> : 'Delete'}
            </button>
        </div>
    );
}

export default ConfigFooter;