import React from 'react';
import './index.css';

import {
    StandardHeader,
    StandardFooter,
} from '../../components';

function Contact() {
    return (
        <>
            <StandardHeader />
            <div id="page">
                <h1>Contact</h1>
                <p>You may contact the developer through any of these provided methods:</p>
                <p>E-Mail: <a href="mailto:hi@autopurger.net">hi@autopurger.net</a></p>
                <p>Discord: Bambusgamer#9801</p>
            </div>
            <StandardFooter />
        </>
    )
}

export default Contact;
