import React from 'react';
import offGreenx128 from './offGreenx128.png';
import offWhitex128 from './offWhitex128.png';
import onRedx128 from './onRedx128.png';
import onWhitex128 from './onWhitex128.png';
import slashx128 from './slashx128.png';
import useMargin from '../../utils/useMargin';
import './index.css';

function MultiSwitch(props) {
    const { SwitchId } = props;

    const getId = (id) => {
        return SwitchId + id;
    }

    const [value, setValue] = React.useState(
        (
            props.defaultValue === 0 ?
                getId('msb-0')
                : props.defaultValue === 1 ?
                    getId('msb-1')
                    : getId('msb-2')
        ) ?? getId('msb-1')
    );

    React.useEffect(() => {
        document.getElementById(value).classList.add('active');
    }, []);

    const handleClick = (id) => {
        document.getElementById(value).classList.toggle('active');
        document.getElementById(id).classList.add('active');
        setValue(id);
        props.handleChange(id === getId('msb-0') ? 0 : id === getId('msb-1') ? 1 : 2);
    };

    return (
        <div id="multi-switch" className="" style={{ marginRight: useMargin() + 'px' }}>
            <button id={getId('msb-0')} className="multi-switch-button left-button hover" onClick={e => handleClick(e.target.id)} disabled={props.loading}>
                <img id="multi-switch-button-image" src={value === getId('msb-0') ? offWhitex128 : offGreenx128} alt="on" />
            </button>
            <button id={getId('msb-1')} className="multi-switch-button middle-button hover" onClick={e => handleClick(e.target.id)} disabled={props.loading}>
                <img id="multi-switch-button-image" src={slashx128} alt="on" />
            </button>
            <button id={getId('msb-2')} className="multi-switch-button right-button hover" onClick={e => handleClick(e.target.id)} disabled={props.loading}>
                <img id="multi-switch-button-image" src={value === getId('msb-2') ? onWhitex128 : onRedx128} alt="on" />
            </button>
        </div>
    );
}

export default MultiSwitch;