import constants from '../../utils/constants';

function TopGG() {

    window.location.href = constants.topGG;

    return (
        <></>
    )
}

export default TopGG;