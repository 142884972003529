import React from 'react';
import './index.css';

import {
    StandardHeader,
    StandardFooter,
} from '../../components';

import constants from '../../utils/constants';

function TOS() {
    return (
        <>
            <StandardHeader />
            <div id="page">
                <h1>Terms of Use</h1>
                <p>These terms are effective as of March 22, 2023.</p>
                <p>These terms apply to</p>
                <ul>
                    <li>Website: <a target="_blank" rel="noreferrer" href="https://autopurger.net/">https://autopurger.net/</a> and all subdomains</li>
                    <li>Discord Bot: AutoPurger#0540 with the ID 979765897675833454</li>
                </ul>
                <p>"This site", "this bot", "this service" and "this website" refers to</p>
                <ul>
                    <li>this website, including its subdomains and any other website through which the Owner makes its Service available;</li>
                    <li>the Application Program Interfaces (API);</li>
                    <li>the Service;</li>
                    <li>the Discord Bot;</li>
                </ul>
                <p>"We", "us" and "the provider" refers to</p>
                <ul>
                    <li>The owner and provider of Autopurger</li>
                    <li>The owner and provider of the website</li>
                    <li>The owner and provider of any other services associated with Autopurger;</li>
                </ul>
                <h1>Imprint &amp; Privacy</h1>
                <p>Privacy Policy: <a target="_blank" rel="noreferrer" href="https://autopurger.net/privacy-policy">https://autopurger.net/privacy-policy</a></p>
                <p>Imprint: <a target="_blank" rel="noreferrer" href="https://autopurger.net/imprint">https://autopurger.net/imprint</a></p>
                <h1>Terms of Use</h1>
                <p>The Service is a copyrighted work owned by Vincent Lauckner. Certain features of the Service may be subject to additional guidelines, terms or rules and are marked as such.</p>
                <p>IF YOU DISAGREE WITH ANY PROVISION OF THESE TERMS, DO NOT REGISTER AND/OR USE THE SERVICE.</p>
                <h1>Access to the Service</h1>
                <p><b>Subject to these Terms.</b> We grant you a non-transferable, non-exclusive, revocable, limited license to access the Service solely for your personal, non-commercial use.</p>
                <p><b>Certain Restrictions.</b> The rights granted to you in these Terms are subject to the following restrictions: (a) you may not sell, rent, lease, transfer, assign, distribute, host or otherwise commercially exploit the Service or access to the Service; (b) you may not modify, derive, disassemble, reverse compile or reverse engineer any portion of the Service; (c) you may not access this Service to create a similar or competing Service; (d) except as expressly provided, no portion of the Service may be copied, reproduced, distributed, republished or transmitted in a downloaded form, except as otherwise provided. All copyright and other proprietary notices contained in the Service must be retained on all copies.</p>
                <p>We reserve the right to modify, suspend or discontinue the Service with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service or any portion thereof.</p>
                <p><b>No Support or Maintenance.</b> You agree that we have no obligation to provide you with support in connection with the Service.</p>
                <p>Except for User Content that you may provide, you understand that all intellectual property rights, including copyrights, patents, trademarks and trade secrets, in the Service and its content belong to us or our suppliers. Note that these Terms and access to the Service do not give you any right, title or interest in any intellectual property rights. We and our suppliers reserve all rights not granted in these Terms.</p>
                <h1>User Content</h1>
                <p><b>User Content.</b> "User Content" means all information and content that a User submits on the Service. You are solely responsible for Your User Content. You bear all risks associated with the use of your User Content. You hereby certify that Your User Content does not violate our Acceptable Use Policy. You must not create the impression to others that your User Content is in any way provided, sponsored or endorsed by the operator of the Service. Because you are solely responsible for your User Content, you may expose yourself to liability. The Operator is not obligated to back up any User Content you post, and your User Content may be deleted at any time without prior notice. You are solely responsible for creating your own backup copies of your User Content, if you so choose.</p>
                <p>You hereby grant to the Operator an irrevocable, non-exclusive, royalty-free, fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit Your User Content, and to grant sublicenses of the foregoing rights, solely for the purpose of including Your User Content on the Service. You hereby irrevocably waive any claims or assertions of moral rights or attribution with respect to Your User Content.</p>
                <p><b>Acceptable Use Policy.</b> The following terms constitute our "Acceptable Use Policy": You agree not to use the Service to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right or any intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</p>
                <p>We reserve the right to review all User Content and, in our sole discretion, investigate and/or take appropriate action against you if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise cause liability to us or any other person. Such actions may include removing or modifying your User Content, terminating your account, and/or reporting you to law enforcement authorities.</p>
                <p>If you submit any feedback or suggestions to us regarding the Service, you hereby assign to us all rights in such feedback and agree that we have the right to use and fully exploit such feedback and related information in any manner it deems appropriate. We will treat any feedback you provide to us as non-confidential and non-proprietary.</p>
                <p>You agree to indemnify and hold us harmless from any claim or demand made by any third party due to or arising out of (a) your use of the Service, (b) your violation of these Terms, (c) your violation of any applicable law or regulation, or (d) your User Content, including costs and attorneys' fees. We reserve the right to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with us in the defense of any such claim. You agree not to settle any matter without our prior written consent. We will use reasonable efforts to notify you of any such claim, suit or proceeding as soon as it becomes known.</p>
                <h1>Third-Party Links &amp; Ads; Other Users</h1>
                <p><b>Third-Party Links &amp; Ads.</b> The Service may contain links to third party services and services and/or display advertisements for third parties. Such third party links and advertisements are not under our control, and we are not responsible for third party links and advertisements. We provide access to these third party links and advertisements only as a convenience to you and do not review, approve, monitor, endorse, warrant or make any representations regarding third party links and advertisements. You use all Third Party Links and Ads at your own risk and should exercise a reasonable degree of caution and discretion in doing so. When you click on a third party link or ad, the terms and policies of that third party apply, including the third party's privacy and data collection practices.</p>
                <p><b>Other Users.</b> Each user of the Service is solely responsible for his or her own User Content. Because we have no control over User Content, you acknowledge and agree that we are not responsible for User Content, whether provided by you or by others. You agree that we will not be liable for any loss or damage resulting from such interactions. If a dispute arises between you and a user of the service, we have no obligation to become involved.</p>
                <p>You hereby release and forever discharge us from any and all past, present and future disputes, claims, controversies, demands, rights, liabilities, actions and causes of action of any kind arising out of or relating directly or indirectly to the Service.</p>
                <h1>Disclaimers</h1>
                <p>The Service is provided on an "as is" and "as available" basis, and we and our suppliers expressly disclaim all warranties and conditions, express, implied or statutory, including any warranties or conditions of merchantability, fitness for a particular purpose, title, undisturbed enjoyment, accuracy or non-infringement. We and our suppliers do not warrant that the service will meet your requirements, that it will be uninterrupted, timely, secure or error-free, or that it will be accurate, reliable, free of viruses or other harmful code, complete, legal or secure.</p>
                <h1>Limitation on Liability</h1>
                <p>To the fullest extent permitted by law, neither we nor our suppliers will be liable to you or any third party for any lost profits, lost data, cost of procurement for substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising out of or in connection with these Terms or your use of or inability to use the Service, even if we have been advised of the possibility of such damages. Access to and use of our Services is at your sole discretion and risk, and you are solely responsible for any damage to your device, computer system, or loss of data in any form.</p>
                <p><b>Term and Termination.</b> Subject to this section, these Terms will remain in full force and effect for as long as you use the Service. We may suspend or terminate your rights to use the Service at any time for any reason in our sole discretion, including use of the Service in violation of these Terms. Upon termination of your rights under these Terms, your account and your right to access and use the Service will terminate immediately. You acknowledge that any termination of your account may result in the deletion of the User Content associated with your account from our live databases. We will not be liable to you in any way for any termination of your rights under these Terms. Even after termination of your rights under these Terms, certain provisions of these Terms will remain in effect.</p>
                <h1>General</h1>
                <p>These Terms may be revised from time to time. If we make material changes, we may notify you via Discord and/or post the changes prominently on our Service. These changes will be effective immediately for new users of our Service. Your continued use of our Service following the posting of such changes will mean that you acknowledge those changes and agree to be bound by the terms and conditions of those changes.</p>
                <p><b>Electronic Communications.</b> Communications between you and us will be made electronically, whether you use the Service or send us e-mails, or whether we post notices on the service or communicate with you by e-mail. For contractual purposes, (a) you agree to receive communications from us in electronic form; and (b) you agree that all terms and conditions, agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal obligations that such communications would satisfy if they were in paper form.</p>
                <p><b>Entire Terms.</b> These Terms constitute the entire agreement between you and us regarding your use of the Service. Our failure to exercise or enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. The section headings in these Terms are for convenience only and have no legal or contractual effect. The word "including" means "including without limitation." If any provision of these Terms is found to be invalid or unenforceable, the other provisions of these Terms shall not be affected thereby and the invalid or unenforceable provision shall be deemed modified so as to be valid and enforceable to the fullest extent permitted by law.</p>
                <p><b>Your Privacy.</b> Please read our Privacy Policy.</p>
                <p><b>Copyright/Trademark Information.</b> Copyright ©. All rights reserved. All trademarks, logos and service marks displayed on the Service are owned by us or other third parties. You may not use these marks without our prior written consent or attribution and linking to us or the consent of the third party that owns the marks.</p>
                <h1>Contact Information</h1>
                <p>Email: <a target="_blank" rel="noreferrer" href="mailto:hi@autopurger.net">hi@autopurger.net</a></p>
                <p>Imprint: <a target="_blank" rel="noreferrer" href="https://autopurger.net/imprint">https://autopurger.net/imprint</a></p>
                <p>Privacy Policy: <a target="_blank" rel="noreferrer" href="https://autopurger.net/privacy-policy">https://autopurger.net/privacy-policy</a></p>
            </div>
            <StandardFooter />
        </>
    )
}

export default TOS;
