import React from 'react';
import './index.css';

import Select from 'react-select';
import chroma from 'chroma-js';

import useMargin from '../../utils/useMargin';


const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "var(--input-grey)",
        border: "2px solid var(--border)",
        borderRadius: "10px",
        ':hover': {
            border: "2px solid var(--bluple-hover)"
        },
        ':focus': {
            border: "2px solid var(--bluple-hover)",
            outline: "none !important"
        },
        '@media (max-width: 600px)': {
            fontSize: "0.8rem;"
        },
        '@media (max-width: 500px)': {
            fontSize: "0.7rem;"
        },
        '@media (max-width: 400px)': {
            fontSize: "0.6rem;"
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            opacity: isDisabled ? 0.3 : 1,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? '#424549'
                        : '#1e2124',
            color: isSelected
                ? chroma.contrast(color, 'white') > 2
                    ? 'white'
                    : 'black'
                : data.color,
            cursor: isDisabled ? 'default' : 'pointer',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
            '@media (max-width: 600px)': {
                fontSize: "0.8rem;"
            },
            '@media (max-width: 500px)': {
                fontSize: "0.7rem;"
            },
            '@media (max-width: 400px)': {
                fontSize: "0.6rem;"
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
        '@media (max-width: 600px)': {
            fontSize: "0.8rem;"
        },
        '@media (max-width: 500px)': {
            fontSize: "0.7rem;"
        },
        '@media (max-width: 400px)': {
            fontSize: "0.6rem;"
        },
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "var(--background)",
    }),
    ':disabled': {
        cursor: 'default !important',
        pointerEvents: 'none !important',
    },
};

function RoleSelectMenu(props) {

    const value = props.options.filter(option => props.selected.includes(option.value))

    return (
        <div className="role-select" style={{ marginRight: useMargin() + 'px' }}>
            <Select
                isMulti
                closeMenuOnSelect={false}
                styles={customStyles}
                classNamePrefix="select"
                options={props.options}
                value={value}
                onChange={values => props.setSelected(values.map(value => value.value))}
                isDisabled={props.loading}
            />
        </div>
    )
}

export default RoleSelectMenu;