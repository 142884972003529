import * as React from 'react';
import {
    RoleSelectMenu,
    MultiSwitch,
    MultiSwitchUser,
    ConfigFooter,
    ConfigHeader,
    RegexInput,
    FilterExplanation,
    TimeInput,
    Switch
} from '../';
import './index.css';

function Config(props) {
    const configId = props.config.id;

    const [name, setName] = React.useState(props.config.name);
    const [nameValid, setNameValid] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);
    const [time, setTime] = React.useState(props.config.time);
    const [timeValid, setTimeValid] = React.useState(true);
    const [unit, setUnit] = React.useState(props.config.unit);
    const [usertype, setUserType] = React.useState(props.config.usertype);
    const [clearOnDelete, setClearOnDelete] = React.useState(props.config.clearOnDelete);
    const [pinned, setPinned] = React.useState(props.config.pinned);
    const [reply, setReply] = React.useState(props.config.reply);
    const [image, setImage] = React.useState(props.config.image);
    const [video, setVideo] = React.useState(props.config.video);
    const [audio, setAudio] = React.useState(props.config.audio);
    const [attachment, setAttachment] = React.useState(props.config.attachment);
    const [link, setLink] = React.useState(props.config.link);
    const [emoji, setEmoji] = React.useState(props.config.emoji);
    const [sticker, setSticker] = React.useState(props.config.sticker);
    const [embed, setEmbed] = React.useState(props.config.embed);
    const [regex, setRegex] = React.useState(props.config.regex);
    const [regexValid, setRegexValid] = React.useState(true);

    const [included, setIncluded] = React.useState(props.config.includedRoles.filter(role => props.roles.some(r => r.id === role)));
    const [excluded, setExcluded] = React.useState(props.config.excludedRoles.filter(role => props.roles.some(r => r.id === role)));

    const [includedOptions, setIncludedOptions] = React.useState(props.roles.filter(role => props.config.includedRoles.includes(role.id)));
    const [excludedOptions, setExcludedOptions] = React.useState(props.roles.filter(role => props.config.excludedRoles.includes(role.id)));

    React.useEffect(() => {
        const includedoptions = props.roles.map(role => ({
            value: role.id,
            label: role.name,
            color: role.color,
            isDisabled: excluded.includes(role.id)
        }))
        const excludedoptions = props.roles.map(role => ({
            value: role.id,
            label: role.name,
            color: role.color,
            isDisabled: included.includes(role.id)
        }))

        setIncludedOptions(includedoptions);
        setExcludedOptions(excludedoptions);
    }, [included, excluded]);

    const handleExpand = () => {
        setExpanded(!expanded);
        document.getElementById('input-container' + configId).classList.toggle('collapsed');
        document.getElementById('config-container' + configId).classList.toggle('row');
    };

    const handleOpenHelp = (e) => {
        document.getElementById(configId + 'show-explanation').classList.add('hide');
        document.getElementById(configId + 'explanation-container').classList.remove('hide');
    };

    const [valid, setValid] = React.useState(true);

    React.useEffect(() => {
        setValid(nameValid && timeValid && regexValid);
    }, [nameValid, timeValid, regexValid]);

    React.useEffect(() => {
        props.setConfig(
            configId,
            {
                id: configId,
                name: name,
                time: time,
                unit: unit,
                includedRoles: included,
                excludedRoles: excluded,
                usertype: usertype,
                clearOnDelete: clearOnDelete,
                pinned: pinned,
                reply: reply,
                image: image,
                video: video,
                audio: audio,
                attachment: attachment,
                link: link,
                emoji: emoji,
                sticker: sticker,
                embed: embed,
                regex: regex,
            }
        )
    }, [name, time, unit, included, excluded, usertype, clearOnDelete, pinned, reply, image, video, attachment, link, emoji, sticker, embed, regex])

    const handleSave = () => {
        props.handleSave(configId);
    };

    const handleDelete = () => {
        props.handleDelete(configId);
    };


    return (
        <div id={"config-container" + configId} className='container config-container row'>
            <ConfigHeader configId={configId} name={name} setName={setName} setValid={setNameValid} expanded={expanded} handleExpand={handleExpand} loading={props.saveLoading || props.deleteLoading} />
            <div id={"input-container" + configId} className='input-container  collapsed'>
                <div className='input-row-container'>
                    <label >Time</label>
                    <TimeInput configId={configId} time={time} setTime={setTime} unit={unit} setUnit={setUnit} setValid={setTimeValid} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Include</label>
                    <RoleSelectMenu options={includedOptions} selected={included} setSelected={setIncluded} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Exclude</label>
                    <RoleSelectMenu options={excludedOptions} selected={excluded} setSelected={setExcluded} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >User type</label>
                    <MultiSwitchUser defaultValue={usertype} handleChange={setUserType} SwitchId={configId + 0} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Clear on delete</label>
                    <Switch defaultValue={clearOnDelete} handleChange={setClearOnDelete} SwitchId={configId + 2} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <FilterExplanation configId={configId} />
                <div id={configId + 'show-explanation'} className='input-row-container'>
                    <label className='show-explanation' onClick={handleOpenHelp}>?explanation</label>
                </div>
                <div className='input-row-container'>
                    <label >Pinned</label>
                    <MultiSwitch defaultValue={pinned} handleChange={setPinned} SwitchId={configId + 3} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Reply</label>
                    <MultiSwitch defaultValue={reply} handleChange={setReply} SwitchId={configId + 4} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains image</label>
                    <MultiSwitch defaultValue={image} handleChange={setImage} SwitchId={configId + 5} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains Video</label>
                    <MultiSwitch defaultValue={video} handleChange={setVideo} SwitchId={configId + 6} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains audio</label>
                    <MultiSwitch defaultValue={audio} handleChange={setAudio} SwitchId={configId + 7} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains other attachment</label>
                    <MultiSwitch defaultValue={attachment} handleChange={setAttachment} SwitchId={configId + 8} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains link</label>
                    <MultiSwitch defaultValue={link} handleChange={setLink} SwitchId={configId + 9} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains emoji</label>
                    <MultiSwitch defaultValue={emoji} handleChange={setEmoji} SwitchId={configId + 10} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains sticker</label>
                    <MultiSwitch defaultValue={sticker} handleChange={setSticker} SwitchId={configId + 11} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Contains embed</label>
                    <MultiSwitch defaultValue={embed} handleChange={setEmbed} SwitchId={configId + 12} loading={props.saveLoading || props.deleteLoading} />
                </div>
                <div className='input-row-container'>
                    <label >Regex</label>
                    <RegexInput configId={configId} regex={regex} setRegex={setRegex} setValid={setRegexValid} loading={props.saveLoading || props.deleteLoading} />
                </div>
            </div >
            <ConfigFooter configId={configId} handleSave={handleSave} handleDelete={handleDelete} valid={valid} changed={props.changed} saveLoading={props.saveLoading} deleteLoading={props.deleteLoading} />
        </div >
    )
}

export default Config;