import React from 'react';
import './index.css';

function Spinner() {

    return (
        <div className="spinner-container">
            <div className="spinner" />
        </div>
    );
}

export default Spinner;