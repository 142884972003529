import React from 'react';
import './index.css';

import offGreenx128 from './offGreenx128.png';
import onRedx128 from './onRedx128.png';
import slashx128 from './slashx128.png';

function FilterExplanation(props) {
    return (
        <div id={props.configId + 'explanation-container'} className="explanation-container hide">
            <div className="explanation-item">
                <div className="image">
                    <img src={offGreenx128} alt="exclude" />
                </div>
                <div className="text">
                    <p>Prevent Message being deleted if match</p>
                </div>
            </div>
            <div className="explanation-item">
                <div className="image">
                    <img src={slashx128} alt="off" />
                </div>
                <div className="text">
                    <p>Delete message regardless</p>
                </div>
            </div>
            <div className="explanation-item">
                <div className="image">
                    <img src={onRedx128} alt="include" />
                </div>
                <div className="text">
                    <p>Only delete Message if match</p>
                </div>
            </div>
        </div>
    )
}

export default FilterExplanation;