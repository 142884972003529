import * as React from 'react';
import './index.css';

import constants from '../../utils/constants';

function StandardHeader() {

    return (
        <div id="header">
            <div id="header-left">
                <img id="app-logo-header" src="https://autopurger.net/logo512.jpg" alt="logo"></img>
                <a id="app-name-header" href={constants.base}>Autopurger</a>
            </div>
            <div id="header-right">
                <a class="header-link" href={constants.base}>Home</a>
                <a class="header-link" href={constants.status}>Status</a>
                <a class="header-link" href={constants.support}>Support</a>
                <a id="invite" class="header-link" href={constants.invite}>Invite</a>
            </div>
        </div>
    )
}

export default StandardHeader;