import * as React from 'react';
import logo from './logo.jpg';
import './index.css';

function StandardHeader() {

    return (
        <div id="footer">
            <div id="footer-left">
                <div id="footer-branding">
                    <img id="footer-logo" src="/static/media/logo.893fd6bc06c6cc6f8b0c.jpg" alt="logo"></img>
                    <a id="app-name-footer" href="https://autopurger.net/">Autopurger</a>
                </div>
                <p id="footer-copyright">Copyright © 2023 - Bambusgamer</p>
            </div>
            <div id="footer-right">
                <a id="footer-link" href="https://autopurger.net/">Home</a>
                <a id="footer-link" href="https://autopurger.net/terms-of-service">Terms of Service</a>
                <a id="footer-link" href="https://autopurger.net/privacy-policy">Privacy Policy</a>
                <a id="footer-link" href="https://autopurger.net/imprint">Imprint</a>
                <a id="footer-link" href="https://autopurger.net/contact">Contact</a>
            </div>
        </div>
    )
}

export default StandardHeader;