import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useFetchConfigs, saveConfig, deleteConfig } from '../../utils/api';
import {
    Header,
    Config,
    Spinner
} from '../../components';

function Setup() {
    const { interactionId } = useLoaderData();

    const [channel, setChannel] = React.useState('');
    const [configs, setConfigs] = React.useState({});
    const [configsBeforeSave, setConfigsBeforeSave] = React.useState({});
    const [roles, setRoles] = React.useState([]);
    const [saveLoading, setSaveLoading] = React.useState({});
    const [deleteLoading, setDeleteLoading] = React.useState({});

    const navigate = useNavigate();
    function handleRedirect() {
        navigate('/invalid');
    }

    const { channel: fetchedChannel, configs: fetchedConfigs, roles: fetchedRoles, loading } = useFetchConfigs(interactionId, handleRedirect);

    React.useEffect(() => {
        setSaveLoading(Object.fromEntries(Object.keys(configs).map(key => [key, false])));
        setDeleteLoading(Object.fromEntries(Object.keys(configs).map(key => [key, false])));
        setChannel(fetchedChannel);
        let obj = {};
        fetchedConfigs.forEach(config => {
            obj[config.id] = {
                ...config
            }
        });
        setConfigs(obj);
        setConfigsBeforeSave(obj);
        setRoles(fetchedRoles);
    }, [fetchedChannel, fetchedConfigs, fetchedRoles]);

    function findInObj(obj, callback) {
        for (const key in obj) {
            if (callback(obj[key], key)) {
                return key;
            }
        }
        return false;
    }

    function generateName(index) {
        let name = 'Config' + ((Object.keys(configs).length > 0 ? Object.keys(configs).length + 1 : '') + (index || ''));
        if (findInObj(configs, (config) => config.name === name)) {
            name = generateName(1 + (index || 0));
        }
        return name;
    }

    function filterObject(obj, callback) {
        return Object.fromEntries(Object.entries(obj).filter(callback));
    }

    const handleCreate = () => {
        const newConfig = {
            id: '_' + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9),
            name: generateName(),
            time: 1,
            includedRoles: [],
            excludedRoles: [],
            unit: 0,
            usertype: 1,
            clearOnDelete: false,
            pinned: 1,
            reply: 1,
            image: 1,
            video: 1,
            audio: 1,
            attachment: 1,
            link: 1,
            emoji: 1,
            sticker: 1,
            embed: 1,
            regex: '',
        };
        setConfigs({ ...configs, [newConfig.id]: newConfig });
        setSaveLoading({ ...saveLoading, [newConfig.id]: false });
    };

    const handleDelete = (id) => {
        setDeleteLoading({ ...deleteLoading, [id]: true });
        deleteConfig(interactionId, id, (result) => {
            setConfigsBeforeSave({ ...filterObject(configs, ([key, value]) => key !== id) });
            setConfigs({ ...filterObject(configs, ([key, value]) => key !== id) });
            setDeleteLoading({ ...filterObject(deleteLoading, ([key, value]) => key !== id) });
        });
    };

    const handleSave = (id) => {
        setSaveLoading({ ...saveLoading, [id]: true });
        saveConfig(interactionId, configs[id], (result, newId) => {
            if (result === 1) {
                setConfigsBeforeSave({ ...filterObject(configsBeforeSave, ([key, value]) => key !== id) });
                setConfigs({ ...filterObject(configs, ([key, value]) => key !== id) });
                setSaveLoading({ ...filterObject(saveLoading, ([key, value]) => key !== id) });
            } else {
                setConfigsBeforeSave({
                    ...filterObject(configsBeforeSave, ([key, value]) => key !== id),
                    [newId || id]: { ...configs[id], id: newId || id }
                });
                setConfigs({
                    ...filterObject(configs, ([key, value]) => key !== id),
                    [newId || id]: { ...configs[id], id: newId || id }
                });
                setSaveLoading({
                    ...filterObject(saveLoading, ([key]) => key !== id),
                    [newId || id]: false
                });
            }
        });
    };

    const setConfig = (id, config) => {
        setConfigs({ ...configs, [id]: config });
    };

    function compare(obj1, obj2) {
        if (!obj1 || !obj2) return true;
        return (
            obj1.name !== obj2.name ||
            obj1.time !== obj2.time ||
            obj1.unit !== obj2.unit ||
            JSON.stringify(obj1.includedRoles) !== JSON.stringify(obj2.includedRoles) ||
            JSON.stringify(obj1.excludedRoles) !== JSON.stringify(obj2.excludedRoles) ||
            obj1.usertype !== obj2.usertype ||
            obj1.clearOnDelete !== obj2.clearOnDelete ||
            obj1.pinned !== obj2.pinned ||
            obj1.reply !== obj2.reply ||
            obj1.image !== obj2.image ||
            obj1.video !== obj2.video ||
            obj1.audio !== obj2.audio ||
            obj1.attachment !== obj2.attachment ||
            obj1.link !== obj2.link ||
            obj1.emoji !== obj2.emoji ||
            obj1.sticker !== obj2.sticker ||
            obj1.embed !== obj2.embed ||
            obj1.regex !== obj2.regex
        )
    }

    return (
        <div id="setup-container">
            <Header channel={channel} configs={configs} handleCreate={handleCreate} />
            {loading ?
                <div id="setup-body" className='container config-container'>
                    <Spinner />
                </div>
                : Object.keys(configs).length > 0 ?
                    Object.entries(configs).map(([key, config]) =>
                        <Config
                            key={key}
                            config={config}
                            roles={roles}
                            setConfig={setConfig}
                            handleDelete={handleDelete}
                            handleSave={handleSave}
                            saveLoading={saveLoading[key]}
                            deleteLoading={deleteLoading[key]}
                            changed={compare(config, configsBeforeSave[key])}
                        />
                    ) :
                    <div id="setup-body" className='config-container'>
                        <h2 id="setup-body-noconfig">No configurations</h2>
                    </div>
            }
        </div>
    );
}

export default Setup;