import React from 'react';
import offRedx128 from './offRedx128.png';
import offWhitex128 from './offWhitex128.png';
import onGreenx128 from './onGreenx128.png';
import onWhitex128 from './onWhitex128.png';
import useMargin from '../../utils/useMargin';
import './index.css';

function MultiSwitch(props) {
    const { SwitchId } = props;

    const getId = (id) => {
        return SwitchId + id;
    }

    const [value, setValue] = React.useState(getId(props.defaultValue ? 'on' : 'off'));

    React.useEffect(() => {
        document.getElementById(value).classList.add('active');
    }, []);

    const handleClick = (id) => {
        document.getElementById(value).classList.toggle('active');
        document.getElementById(id).classList.add('active');
        setValue(id);
        props.handleChange(id === getId('on') ? true : false);
    };

    return (
        <div id="switch" className="" style={{ marginRight: useMargin() + 'px' }}>
            <button id={getId('off')} className="switch-button left-button hover" onClick={e => handleClick(e.target.id)} disabled={props.loading}>
                <img id="switch-button-image" src={value === getId('off') ? offWhitex128 : offRedx128} alt="on" />
            </button>
            <button id={getId('on')} className="switch-button right-button hover" onClick={e => handleClick(e.target.id)} disabled={props.loading}>
                <img id="switch-button-image" src={value === getId('on') ? onWhitex128 : onGreenx128} alt="on" />
            </button>
        </div>
    );
}

export default MultiSwitch;