const { useState, useEffect } = require("react");

function getWindowDimensions() {
    const { innerWidth: width } = window;

    let marignRight = 0;

    if (width > 750) {
        marignRight = width - 700 - width * 0.05;
    } else {
        marignRight = 0;
    }
    return marignRight;
}

function useMargin() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default useMargin;