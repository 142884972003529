import React from 'react';
import './index.css';

import useMargin from '../../utils/useMargin';

function TimeInput(props) {

    function handleChange(e) {
        if (isNaN(e.target.value) || e.target.value > 999 || e.target.value < 1) {
            document.getElementById(props.configId + '-time').classList.add('invalid');
            props.setValid(false);
        } else {
            document.getElementById(props.configId + '-time').classList.remove('invalid');
            props.setValid(true);
        }
        props.setTime(e.target.value);
    }

    const inputClasses = 'time-input '// + (props.loading ? 'loading' : '');

    return (
        <div className="time-input-container" style={{ marginRight: useMargin() + 'px' }}>
            <input id={props.configId + '-time'} type="number" min="1" max="999" className={inputClasses} value={props.time} onChange={handleChange} disabled={props.loading} />
            <select className="unit-select" value={props.unit} onChange={e => props.setUnit(e.target.value)} disabled={props.loading}>
                <option value={0}>Minutes</option>
                <option value={1}>Hours</option>
                <option value={2}>Days</option>
            </select>
        </div>
    )
}

export default TimeInput;