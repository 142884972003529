import React from 'react';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import './index.css';

function ConfigHeader(props) {

    const handleChange = (e) => {
        props.setName(e.target.value);
        if (e.target.value === '' || e.target.value.startsWith(' ') || e.target.value.endsWith(' ') || e.target.value.length > 20) {
            props.setValid(false);
            document.getElementById(props.configId + '-name').classList.add('invalid');
        } else {
            props.setValid(true);
            document.getElementById(props.configId + '-name').classList.remove('invalid');
        }
    }

    const classes = 'config-name-input ' + (props.loading ? 'loading' : '');

    return (
        <div className="config-header">
            <button onClick={props.handleExpand} className="expand-collapse-button">
                {props.expanded ? <ExpandLessRoundedIcon className="expand-collapse-icon" /> : <ExpandMoreRoundedIcon className="expand-collapse-icon" />}
            </button>
            <input id={props.configId + '-name'} type="text" className={classes} value={props.name} onChange={handleChange} disabled={props.loading} />
        </div>
    );
}

export default ConfigHeader;