import React from 'react';
import './index.css';

import {
    StandardHeader,
    StandardFooter,
} from '../../components';

function Imprint() {
    return (
        <>
            <StandardHeader />
            <div id="page">
                <h1>Impressum</h1>
                <h2>Angaben gemäß § 5 TMG</h2>
                <p>Vincent Lauckner</p>
                <p>Robert-Koch-Straße 3</p>
                <p>08359 Rittersgrün</p>
                <h2>Kontakt</h2>
                <p>Telefon: <a href="tel:+4917648060112">+49 176 48060112</a></p>
                <p>E-Mail: <a href="mailto:hi@autopurger.net">hi@autopurger.net</a></p>
                <h2>Redaktionell verantwortlich</h2>
                <p>Vincent Lauckner</p>
                <p>Robert-Koch-Straße 3</p>
                <p>08359 Rittersgrün</p>
                <h2>EU-Streitschlichtung</h2>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" rel="noreferrer">https://ec.europa.eu/consumers/odr/</a>.</p>
                <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                <h2>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                <h2>Bildnachweis</h2>
                <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
                <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
                <ul>
                    <li>Discord Inc.</li>
                </ul>
                <p>Alle Texte sind urheberrechtlich geschützt.</p>
            </div>
            <StandardFooter />
        </>
    )
}

export default Imprint;
