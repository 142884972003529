import axios from 'axios';
import { useState, useEffect } from 'react';
import constants from './constants';

export function useFetchConfigs(interactionId, handleRedirect) {
    const [channel, setChannel] = useState('');
    const [configs, setConfigs] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(constants.api + interactionId)
            .then(res => {
                setConfigs(res.data.configs);
                setRoles(res.data.roles);
                setChannel(res.data.channel);
            })
            .catch(handleRedirect)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return { channel, configs, roles, loading };
}

export function saveConfig(interactionId, config, setResult) {
    axios.post(constants.api + interactionId, {
        config: {
            id: config.id,
            name: config.name,
            time: config.time,
            unit: config.unit,
            includedRoles: config.includedRoles,
            excludedRoles: config.excludedRoles,
            usertype: config.usertype,
            clearOnDelete: config.clearOnDelete,
            pinned: config.pinned,
            reply: config.reply,
            image: config.image,
            video: config.video,
            audio: config.audio,
            attachment: config.attachment,
            link: config.link,
            emoji: config.emoji,
            sticker: config.sticker,
            embed: config.embed,
            regex: config.regex,
        }
    })
        .then(res => {
            setResult(0, res.data.id);
        })
        .catch((err) => {
            console.error(err);
            setResult(1);
        })
}

export function deleteConfig(interactionId, configId, setResult) {
    axios.delete(constants.api + interactionId + '/' + configId)
        .then(res => {
            setResult(0);
        })
        .catch((err) => {
            console.error(err);
            setResult(1);
        })
}