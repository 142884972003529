import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import './index.css';
import {
  Invalid,
  ErrorPage,
  Setup,
  Invite,
  TOS,
  Privacy,
  Imprint,
  Contact,
  TopGG,
} from './routes';
import constants from './utils/constants';

const router = createBrowserRouter([
  {
    path: '/',
    element: <TopGG />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/:interactionId',
    element: <Setup />,
    loader: function ({ params }) {
      return { interactionId: params.interactionId };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/invite',
    element: <Invite />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/invalid',
    element: <Invalid />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/terms-of-service',
    element: <TOS />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/privacy-policy',
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/imprint',
    element: <Imprint />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/contact',
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);