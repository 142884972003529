import React from 'react';
import './index.css';

import {
    StandardHeader,
    StandardFooter,
} from '../../components';

function Privacy() {
    return (
        <>
            <StandardHeader />
            <div id="page">
                <h1>Privacy Policy</h1>
                <p>Your privacy is important to us. Our intention is to protect your data in the best possible way across our different presences, including our website <a href="https://autopurger.net/">https://autopurger.net/</a> and all our social media appearances. </p>
                <p>This policy is effective as of March 22, 2023.</p>
                <hr />
                <h2>Terms &amp; Imprint</h2>
                <p>Terms: <a href="https://autopurger.net/terms-of-service">https://autopurger.net/terms-of-service</a></p>
                <p>Imprint: <a href="https://autopurger.net/imprint">https://autopurger.net/imprint</a></p>
                <hr />
                <h1>General</h1>
                <p>We do not share any of your personal or Discord server data with third-party services. We may use your information to improve our services, with and without personally identifying you.</p>
                <p>We may log all messages posted on your server to provide our service while only storing message ids and other metadata temporary.</p>
                <hr />
                <h1>Information we Collect</h1>
                <p>Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services.</p>
                <h1>Log Data</h1>
                <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, other details about your visit, and technical details that occur in conjunction with any errors you may encounter.</p>
                <p>Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.</p>
                <h1>Collection and Use of Information</h1>
                <p>We may collect personal information from you when you do any of the following on our website:</p>
                <ul>
                    <li>Use a mobile device or web browser to access our content</li>
                    <li>Contact us via email, social media, or on any similar technologies</li>
                </ul>
                <p>We may log all messages posted on your server. We only do this to provide our services. These logged messages are securely stored in our database without any intern or publicly available endpoint, making it impossible for anyone to get messages without direct database access. We will not share any logged messages with third-party services.</p>
                <p>If not said otherwise, we may not automatically delete collected/stored log data.</p>
                <h1>Security of Your Personal Information</h1>
                <p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized (third-party) access, disclosure, copying, use, or modification.</p>
                <p>Although we do our best to protect the personal information you provide to us, please note that no method of electronic transmission or storage is 100% secure and no one can guarantee absolute data security. We will comply with the laws applicable to us with respect to any data breach.</p>
                <p>You are responsible for the choice of password and its overall security strength to ensure the security of your own data within our services.</p>
                <h1>Affiliations and Partners</h1>
                <p>We may use content from other websites and services while not being affiliated with them.</p>
                <p>Autopurger is not affiliated with the following companies and/or services:</p>
                <ul>
                    <li>Discord Inc (<a href="https://discord.com">discord.com</a>)</li>
                </ul>
                <hr />
                <h1>Children's Privacy</h1>
                <p>Our products and services are not directly targeted to children under the age of 16, and we do not knowingly collect personal information from children under the age of 16.</p>
                <hr />
                <h1>Limits of Our Policy</h1>
                <p>Our website may contain links to external websites that are not operated by us. Please note that we have no control over the content and policies of these sites and cannot accept any responsibility or liability for their privacy practices.</p>
                <hr />
                <h1>Changes to this Policy</h1>
                <p>We have the discretion to change our Privacy Policy to reflect updates to our business processes, current acceptable practices, or legal or regulatory changes. If we decide to change this Privacy Policy, we will post the changes here at the same link that you use to access this Privacy Policy.</p>
                <p>When required by law, we will obtain your consent or provide you with the opportunity to consent to or decline any new use of your personal information.</p>
                <hr />
                <h1>Contact</h1>
                <p>For any questions or concerns regarding your privacy, you may contact us through email: <a href="mailto:privacy@autopurger.net">privacy@autopurger.net</a><br /><a href="https://autopurger.net/imprint">Click here</a> for more information.</p>
            </div>
            <StandardFooter />
        </>
    )
}

export default Privacy;
