import * as React from 'react';
import logo from './logo.jpg';
import './index.css';

function Header(props) {
    const { channel, configs, handleCreate } = props;
    const length = Object.keys(configs).length;

    return (
        <div id="header" className="container row">
            <img id="logo" src={logo} alt="logo" />
            <h1 id="setup-heading">{`Setup${channel ? ` - ${channel.slice(0, 25)}${channel.length > 25 ? '...' : ''}` : ''}`}</h1>
            <button id="new-button" className={length < 5 ? 'enabled' : 'disabled'} onClick={handleCreate} disabled={length > 4}>Create new</button>
        </div>
    )
}

export default Header;